import { Elev } from "@/model/elev";
import { Await, useAsyncValue, useLoaderData, useParams, useRevalidator } from "react-router-dom";
import ElevTabelSkeleton from "./ElevTabelSkeleton";
import React, { useEffect, useRef, useState } from "react";
import ElevTabel from "./ElevTabel";
import ActivateEleverDialog from "../dialog/ActivateEleverDialog";
import AdgangskodePolitikDialog from "../dialog/AdgangskodePolitikDialog";
import FortrydEleverAccessDialog from "../dialog/FortrydEleverAccessDialog";
import GiveEleverAccessDialog from "../dialog/GiveEleverAccessDialog";
import RecallResetPasswordDialog from "../dialog/RecallResetPasswordDialog";
import ResetPasswordDialog from "../dialog/ResetPasswordDialog";
import SpaerElevDialog from "../dialog/SpaerElevDialog";
import useElever from "@/hooks/elever";
import ElevTabelButtonRow from "./ElevTabelButtonRow";
import { ResetPasswordResponse } from "@/types/resetPassword";
import usePagination from "@/hooks/pagination";
import Pagination from "../pagination/Pagination";
import { DEFAULT_PAGINATION_AMOUNT } from "@/constants/pagination";
import SearchField from "../search/SearchField";
import useSearch from "@/hooks/search";
import { ElevListResponse } from "@/types/elevListeResponse";
import useToastStore from "@/store/toastStore";
import { createToastElevListFejl } from "@/util/toast";
import StatusBesked from "../statusbesked/StatusBesked";
import SeMereLink from "@/components/error/SeMereLink";

export default function ElevTabelFetch() {
  const { elevListResponse } = useLoaderData() as { elevListResponse: ElevListResponse };

  return (
    <>
      <React.Suspense fallback={<ElevTabelSkeleton/>}>
        <Await resolve={elevListResponse}>
          <ElevTabelContainer/>
        </Await>
      </React.Suspense>
    </>
  );
}

function ElevTabelContainer() {
  const elevListResponse = useAsyncValue() as ElevListResponse;
  const initialElever = elevListResponse.elever;
  const { revalidate } = useRevalidator();
  const activateDialog = useRef<HTMLDialogElement>(null);
  const giveAccessDialog = useRef<HTMLDialogElement>(null);
  const fortrydAccessDialog = useRef<HTMLDialogElement>(null);
  const policyDialog = useRef<HTMLDialogElement>(null);
  const spaerDialog = useRef<HTMLDialogElement>(null);
  const resetPasswordDialog = useRef<HTMLDialogElement>(null);
  const recallResetPasswordDialog = useRef<HTMLDialogElement>(null);
  const { institutionId } = useParams();
  const [spaerElev, setSpearElev] = useState<Elev>();
  const [selectPolicyElev, setSelectedPolicyElev] = useState<Elev>();
  const [checked, setChecked] = useState(false);
  const { addToast } = useToastStore();
  // changes in key will trigger re-render of the student table
  const [rerenderKey, setRerenderKey] = useState(Date.now());


  const validateElevListResponse = (institutionId: string) => {
    if (elevListResponse.statusCode.code === 500) {
      addToast(createToastElevListFejl(institutionId));
    }
  };

  useEffect(() => {
    validateElevListResponse(institutionId as string);
    // Nulstil når man skifter institution
    onCheckAll(false);
    setCheckedElever([]);
    resetPagination();
    performSearch("");
  }, [institutionId]);

  const onCheckAll = (value: boolean) => {
    setChecked(value);
    value ? setCheckedElever([...currentData]) : setCheckedElever([]);
  };

  const invokeShowSpaerElev = (elev: Elev) => {
    setSpearElev(elev);
    spaerDialog.current?.showModal();
  };
  const invokeShowPolicy = (elev: Elev) => {
    setSelectedPolicyElev(elev);
    policyDialog.current?.showModal();
  };

  const { searchResult, performSearch } = useSearch(initialElever, [
    "getFullName",
    "getInstitutionClass",
    "getKeycloakUsername",
    "getFirstName",
    "getLastName",
  ]);

  const {
    currentData,
    nextPage,
    previousPage,
    getNumberOfPages,
    currentPage,
    goToPage,
    totalEntries,
    setAmountToShow,
    resetPagination,
    setCurrentPage,
  } = usePagination(searchResult, 1, DEFAULT_PAGINATION_AMOUNT);

  const {
    eleverToActivate,
    eleverToFortrydGiveAccess,
    eleverToGiveAccess,
    eleverToRecallResetPassword,
    eleverToResetPassword,
    setCheckedElever,
    updateEleverResetUntilTime,
    onChecked,
  } = useElever(currentData);

  function resetTableResults() {
    setCheckedElever([]);
    setChecked(false);
    setRerenderKey(Date.now());
  }

  const onUpdateAmount = (amount: number) => {
    setAmountToShow(amount);
    setCurrentPage(1);
    resetTableResults();
  };

  const onGiveAccess = () => {
    giveAccessDialog.current?.close();
    revalidate();
  };

  const onFortrydGiveAccess = () => {
    fortrydAccessDialog.current?.close();
    revalidate();
  };

  const onUpdatePolicy = () => {
    policyDialog.current?.close();
    revalidate();
  };
  const onToggleSpaerring = () => {
    spaerDialog.current?.close();
    revalidate();
  };

  const onResetPassword = (result: ResetPasswordResponse) => {
    if (result.statusCode.code === 200) {
      updateEleverResetUntilTime(result);
    }
    resetPasswordDialog.current?.close();
  };

  const onRecallResetPassword = (result: ResetPasswordResponse) => {
    if (result.statusCode.code === 200) {
      updateEleverResetUntilTime(result);
    }
    recallResetPasswordDialog.current?.close();
  };

  const onMassActivate = () => {
    revalidate();
    activateDialog.current?.close();
  };

  const onSearch = (searchTerm: string) => {
    performSearch(searchTerm);
    resetTableResults();
    resetPagination();
  };

  const onNextPage = () => {
    setChecked(false);
    nextPage();
  }

  const onPreviousPage = () => {
    setChecked(false);
    previousPage();
  }

  const onGoToPage = (pageNumber: number) => {
    setChecked(false);
    goToPage(pageNumber);
  }

  return (
    <>
      <ActivateEleverDialog
        elever={eleverToActivate}
        ref={activateDialog}
        onActivate={onMassActivate}
        close={() => activateDialog.current?.close()}
      />
      <GiveEleverAccessDialog
        elever={eleverToGiveAccess}
        ref={giveAccessDialog}
        onGiveAccess={onGiveAccess}
        close={() => giveAccessDialog.current?.close()}
      />
      <FortrydEleverAccessDialog
        elever={eleverToFortrydGiveAccess}
        ref={fortrydAccessDialog}
        onFortrydGiveAccess={onFortrydGiveAccess}
        close={() => fortrydAccessDialog.current?.close()}
      />
      <AdgangskodePolitikDialog
        elev={selectPolicyElev}
        ref={policyDialog}
        close={() => policyDialog.current?.close()}
        onUpdatePolicy={onUpdatePolicy}
      />
      <SpaerElevDialog
        elev={spaerElev}
        ref={spaerDialog}
        close={() => spaerDialog.current?.close()}
        onToggleSpaerring={onToggleSpaerring}
      />
      <ResetPasswordDialog
        elever={eleverToResetPassword}
        ref={resetPasswordDialog}
        close={() => resetPasswordDialog.current?.close()}
        onResetPasswords={onResetPassword}
      />
      <RecallResetPasswordDialog
        elever={eleverToRecallResetPassword}
        ref={recallResetPasswordDialog}
        closeModal={() => recallResetPasswordDialog.current?.close()}
        onResetPasswords={onRecallResetPassword}
      />
      {elevListResponse.elever.length > 0 ? (
        <>
          <div className="d-flex justify-content-between">
            <div>
              <ElevTabelButtonRow
                onActivateElever={() => activateDialog.current?.showModal()}
                eleverToActivate={eleverToActivate.length}
                eleverToGiveAccess={eleverToGiveAccess.length}
                eleverToFortrydeGivadgang={eleverToFortrydGiveAccess.length}
                onFortrydGivAdgang={() => fortrydAccessDialog.current?.showModal()}
                onGiveEleverAccess={() => giveAccessDialog.current?.showModal()}
                eleverToFortrydResetpassword={eleverToRecallResetPassword.length}
                eleverToResetPassword={eleverToResetPassword.length}
                onRecallResetPassword={() => recallResetPasswordDialog.current?.showModal()}
                onResetPassword={() => resetPasswordDialog.current?.showModal()}
              />
            </div>

            <SearchField onSearch={onSearch}/>
          </div>

          <ElevTabel key={rerenderKey}
            elever={currentData}
            checked={checked}
            onChecked={onChecked}
            onActivatedElev={revalidate}
            invokeShowPolicy={invokeShowPolicy}
            invokeShowSpaerElev={invokeShowSpaerElev}
            onCheckAll={onCheckAll}
            className="mt-5"
          />

          <Pagination
            currentPage={currentPage}
            numberOfPages={getNumberOfPages}
            totalEntries={totalEntries}
            onGoToPage={onGoToPage}
            onNextPage={onNextPage}
            onPreviousPage={onPreviousPage}
            onSelectAmount={onUpdateAmount}
            key={institutionId}
          />
        </>
      ) : (
        <StatusBesked type="warning" header="Ingen elever fundet">
          Du skal være tilknyttet en klasse i skolens administrationssystem, for at kunne se elever her.
          <SeMereLink/>
        </StatusBesked>
      )}
    </>
  );
}
