import { useState } from "react";

type SearchFieldType = {
  onSearch: (searchTerm: string) => void;
};

export default function SearchField({ onSearch }: SearchFieldType) {
  const [search, setSearch] = useState("");
  const searchOnEnter = (keyCode: string) => {
    if (keyCode === "Enter") {
      onSearch(search);
    }
  }
  return (
    <div className="search">
      <label htmlFor="search" className="sr-only">Fremsøg elev på navn, klasse eller Unilogin brugernavn</label>
      <input
        className="form-input input-char-27"
        id="search"
        name="search-button"
        type="search"
        value={search}
        onChange={({ currentTarget }) => setSearch(currentTarget.value)}
        onKeyDown={(key) => searchOnEnter(key.code)}
      />
      <button className="button button-search" onClick={() => onSearch(search)}>Søg</button>
    </div>
  );
}
